<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="商品名称" prop="spuName">
                <el-input v-model="query.spuName" placeholder="请输入商品名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <!-- <el-form-item label="商品分类" prop="spuType" >
                <el-select v-model="query.spuType" placeholder="请选择商品类型" clearable size="small">
                    <el-option v-for="dict in spuTypeList" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item> -->
            <el-form-item label="上架状态" prop="publishStatus">
                <el-select v-model="query.publishStatus" placeholder="请选择上架状态" clearable size="small">
                    <el-option v-for="dict in spuPublishStatus" :key="dict.value" :label="dict.label"
                        :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-hasPermi="['pms:spuInfo:add']">新增</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
                    v-hasPermi="['pms:spuInfo:edit']">修改</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
                    v-hasPermi="['pms:spuInfo:remove']">删除</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="primary" plain icon="el-icon-top" size="mini" :disabled="multiple"
                    @click="handleBatchUpStatus" v-hasPermi="['pms:spuInfo:upStatus']">批量上架</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="warning" plain icon="el-icon-bottom" size="mini" :disabled="multiple"
                    @click="handleBatchDownStatus" v-hasPermi="['pms:spuInfo:upStatus']">批量下架</el-button>
            </el-col>

            <!-- <right-toolbar @queryTable="getList"></right-toolbar> -->
        </el-row>

        <el-table v-loading="loading" :data="spuInfoList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="排序" align="center" prop="spuSort" />
            <!-- <el-table-column label="商品编号" align="center" prop="skuInfo.skuId" width="180" >
                <template #default="scope">
                    <span style="color: #67c23a">{{ scope.row.skuInfo.skuId }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="商品图片" align="center" prop="skuInfo.skuDefaultImg">
                <template v-slot="slot">
                    <div v-if="slot.row.skuInfo.skuDefaultImg == null || slot.row.skuInfo.skuDefaultImg == ''">未上传图片</div>
                    <img :src="slot.row.skuInfo.skuDefaultImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="spuName" />
            <!-- <el-table-column label="单价" align="center" prop="skuInfo.price" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.skuInfo.price).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="划线价" align="center" prop="skuInfo.linePrice" >
                <template #default="scope">
                    <span style="font-size: 18px; color: gainsboro; text-decoration: line-through;">￥{{ (scope.row.skuInfo.linePrice).toFixed(2) }}</span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="一级分佣（%）" align="center" prop="skuInfo.oneSale" width="120">
                <template #default="scope">
                    <span style="color: red">{{ (scope.row.skuInfo.oneSale).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="二级分佣（%）" align="center" prop="skuInfo.twoSale" width="120">
                <template #default="scope">
                    <span style="color: red">{{ (scope.row.skuInfo.twoSale).toFixed(2) }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="提成比例（%）" align="center" prop="skuInfo.goodsSale" width="120">
                <template #default="scope">
                    <span style="color: red">{{ (scope.row.skuInfo.goodsSale).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="销量" align="center" prop="skuInfo.saleCount" />
            <el-table-column label="库存" align="center" prop="skuInfo.inventory" />
            <el-table-column label="上架状态" align="center" prop="publishStatus">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.publishStatus == '1'?'success':'danger'">{{ scope.row.publishStatus == '1'?'上架':'下架' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="商品类型" align="center" prop="spuType">
                <template slot-scope="scope">
                    <el-tag type="success" >{{ scope.row.spuType == '1' ? '商品' : '水票' }}</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="handleUpdate(scope.row)" v-hasPermi="['pms:spuInfo:edit']">修改</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete"
                        @click="handleDelete(scope.row)" v-hasPermi="['pms:spuInfo:remove']">删除</el-button>
                    <el-button v-show="scope.row.publishStatus == '0'" size="mini"
                        type="text" icon="el-icon-edit" @click="handleUpStatus(scope.row)"
                        v-hasPermi="['pms:spuInfo:upStatus']">上架</el-button>
                    <el-button v-show="scope.row.publishStatus == '1'" size="mini"
                        type="text" icon="el-icon-delete" @click="handleDownStatus(scope.row)"
                        v-hasPermi="['pms:spuInfo:upStatus']">下架</el-button>

                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'Product',
    data() {
        return {
            // 商品类型
            spuTypeList: [
                { value: 2, label: '实物商品' },
                { value: 1, label: '虚拟商品' },
            ],
            // 上下架
            spuPublishStatus: [
                { value: 0, label: '下架' },
                { value: 1, label: '上架' },
            ],
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 商品表格数据
            spuInfoList: [],
            // 查询参数
            query: {
                current: 1,
                size: 10,
                spuName: '',
                spuType: null,
                publishStatus: null,
            }
        }
    },
    created() {
        if(this.$route.query.pageNum) {
            this.current = this.$route.query.pageNum
        }
        this.getList()
    },
    methods: {
        /** 获取商品列表 */
        getList() {
            this.loading = true;
            api.getPageSpuList(this.query).then(res => {
                console.log(res);
                this.spuInfoList = res.records
                this.total = res.total
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        /** 表单重置 */
        reset() {
            this.query = {
                current: 1,
                size: 10,
                skuName: '',
                skuType: null,
            }
            this.getList()
        },
        /** 搜索按钮 */
        handleQuery() {
            this.query.current = 1;
            this.getList();
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            console.log("添加商品");
            this.$router.push("/pms/add")
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const tableId = row.id || this.ids
            this.$router.push({ path: '/pms/edit', query: { tableId: tableId, current: this.query.current } });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除商品编号为"' + ids + '"的数据项？').then(function () {
                return api.removeGoods({ids: ids + ''});
            }).then(() => {
                this.getList();
                this.$message.success("删除成功");
            }).catch(() => { });
        },
        /** 批量下架 */
        handleBatchUpStatus() {
            this.UpStatus(this.ids, '1');
        },
        /** 批量下架 */
        handleBatchDownStatus() {
            this.UpStatus(this.ids, '0');
        },
        /** 下架 */
        handleDownStatus(data) {
            this.UpStatus(data.id, '0');
        },
        /** 上架 */
        handleUpStatus(data) {
            this.UpStatus(data.id, '1');
        },
        /** 上下加方法 */
        UpStatus(id, type) {
            api.upStatusSpuInfo({
                ids: id + '',
                type: type
            }).then(res => {
                this.$message.success("操作成功");
                this.getList();
            }).catch(() => {
                console.log(123)
            });

        },

    }
}
</script>